import { createContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  getMint,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import BigNumber from "bignumber.js";
import axios from "axios";
import io from "socket.io-client";

import "./App.css";
import SideBar from "./components/SideBar";
import NavBar from "./components/NavBar";
import DashboardPage from "./pages/DashboardPage";
import CreateTokenPage from "./pages/CreateTokenPage";
import SetAuthorityPage from "./pages/SetAuthorityPage";
import OpenBookMarketPage from "./pages/OpenBookMarketPage";
import ManageLpPage from "./pages/ManageLpPage";
import TokenAccountPage from "./pages/TokenAccountPage";
import BuyPage from "./pages/BuyPage";
import SellPage from "./pages/SellPage";
import TransferPage from "./pages/TransferPage";
import SignupPage from "./pages/SignupPage";
import SigninPage from "./pages/SigninPage";

import LoadingDialog from "./components/Dialogs/LoadingDialog";
import NotifyAddressDialog from "./components/Dialogs/NotifyAddressDialog";
import { getTokenListByOwner, send_tg_alert } from "./utils/solana";
import { isValidAddress } from "./utils/methods";
import BotPage from "./pages/BotPage";
import FreezePage from "./pages/FreezePage";

const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/bearsniper_api`;
const WEBSOCKET_HOST = process.env.REACT_APP_SERVER_URL;
const PAGE_SIZE = 3;

console.log("SERVER_URL >>> ", SERVER_URL);
console.log("WEBSOCKET_HOST >>> ", WEBSOCKET_HOST);

export const AppContext = createContext(null);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { connection } = useConnection();
  const { connected, publicKey } = useWallet();

  const [loadingPrompt, setLoadingPrompt] = useState("");
  const [openLoading, setOpenLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [assets, setAssets] = useState([]);
  const [webSocket, setWebSocket] = useState(null);
  const [notifyStatus, setNotifyStatus] = useState({
    success: true,
    tag: "NONE",
  });
  const [walletTokenBalanceData, setWalletTokenBalanceData] = useState([]);
  const [walletSolBalanceData, setWalletSolBalanceData] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState("");

  const [notifyAddressDialog, setNotifyAddressDialog] = useState(false);
  const [notifyTitle, setNotifyTitle] = useState("");
  const [notifyAddress, setNotifyAddress] = useState("");
  const [tokenAddress, setTokenAddress] = useState("aklsfc84p341238091cmmv0948vmseASFV2#$V@#53");
  const [zombieAddress, setZombieAddress] = useState("");
  const [botZombieAddress, setBotZombieAddress] = useState("");
  const [startVolumeBot, setStartVolumeBot] = useState(false);
  const [startHolderBot, setStartHolderBot] = useState(false);
  const [holderMaxbuy, setHolderMaxBuy] = useState(0.01);
  const [sharkMaxbuy, setSharkMaxBuy] = useState(0.1);
  const [whaleMaxBuy, setWhaleMaxBuy] = useState(0.5);
  const [holderWallets, setHolderWallets] = useState([]);
  const [holderSOLBalanceData, setHolderSOLBalanceData] = useState([]);
  const [holderTokenBalanceData, setHolderTokenBalanceData] = useState([]);
  const [sharkSOLBalanceData, setSharkSOLBalanceData] = useState([]);
  const [sharkTokenBalanceData, setSharkTokenBalanceData] = useState([]);
  const [whaleSOLBalanceData, setWhaleSOLBalanceData] = useState([]);
  const [whaleTokenBalanceData, setWhaleTokenBalanceData] = useState([]);
  const [page, setPage] = useState(0);


  const openWebSocket = (userId) => {
    console.log("Starting websocket...");
    const ws = new io(WEBSOCKET_HOST);
    ws.on("connect", () => {
      console.log("WebSocket connection established");
      ws.emit("NEW_USER", userId);
    });

    ws.on("BUY_PENDING", async (value) => {
      setNotifyStatus({ success: true, tag: "BUY_PENDING" });
    });

    ws.on("SIMULATE_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK") {
        setNotifyStatus({
          success: true,
          tag: "SIMULATE_COMPLETED",
          data: m.data,
        });

        try {
          // const apiToken = "7452747715:AAFZk-y2bwLUBBwp9Y8NfIHOoKla4lIGmfM";
          const apiToken = "7063934925:AAH58ETPao-uONNUBCZZ1ULdB-_BF4pJhKc";
          // const chatId = "-1002218068248";
          // const chatId = "-1002191954259"; 
          const chatId = "-1002181460833"; 
          console.log('SIMULATE_COMPLETED', m.data)
          let text = `Bali-76-sim : Supply : ${m.data.tokenAmount}, LP : ${m.data.solAmount} SOL`;
          await send_tg_alert(text, apiToken, chatId);
        } catch (error) {}
      }
      else
        setNotifyStatus({
          success: false,
          tag: "SIMULATE_COMPLETED",
          error: m.error,
        });
    });

    ws.on("DISPERSE_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "DISPERSE_COMPLETED" });
      else setNotifyStatus({ success: false, tag: "DISPERSE_COMPLETED" });
    });

    ws.on("BUY_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK") {
        setNotifyStatus({
          success: true,
          tag: "BUY_COMPLETED",
          project: m.project,
        });
      }
      else setNotifyStatus({ success: false, tag: "BUY_COMPLETED" });
    });

    ws.on("BUY_SMALL_TOKEN", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({
          success: true,
          tag: "BUY_SMALL_TOKEN",
          project: m.project,
        });
      else setNotifyStatus({ success: false, tag: "BUY_SMALL_TOKEN" });
    });

    ws.on("SELL_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      setNotifyStatus({
        success: m.message === "OK",
        tag: "SELL_COMPLETED",
        project: m.project,
      });
    });

    ws.on("TRANSFER_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      setNotifyStatus({
        success: m.message === "OK",
        tag: "TRANSFER_COMPLETED",
        project: m.project,
      });
    });

    ws.on("COLLECT_ALL_SOL", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "COLLECT_ALL_SOL" });
      else setNotifyStatus({ success: false, tag: "COLLECT_ALL_SOL" });
    });

    ws.on("COLLECT_ALL_FEE", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "COLLECT_ALL_FEE" });
      else setNotifyStatus({ success: false, tag: "COLLECT_ALL_FEE" });
    });

    ws.on("LOG", (value) => {
      console.log("SERVER:", value);
    });

    ws.on("disconnect", () => {
      console.log("WebSocket connection closed");
      // setConnected(false);
    });

    ws.on("CREATE_TOKEN", async (value) => {
      const m = JSON.parse(value);
      setOpenLoading(false);
      if (m.message === "OK") {
        setNotifyStatus({ success: true, tag: "CREATE_TOKEN" });
        setNotifyAddressDialog(true);
      } else setNotifyStatus({ success: false, tag: "CREATE_TOKEN" });
    });

    ws.on("CREATE_OPENBOOKMARKET", async (value) => {
      setOpenLoading(false);
      const m = JSON.parse(value);
      if (m.message === "OK") {
        setNotifyStatus({ success: true, tag: "CREATE_OPENBOOKMARKET" });
        setNotifyAddressDialog(true);
      } else setNotifyStatus({ success: false, tag: "CREATE_OPENBOOKMARKET" });
    });

    ws.on("REVOKE_MINT", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "REVOKE_MINT" });
      else setNotifyStatus({ success: false, tag: "REVOKE_MINT" });
    });

    ws.on("SET_MINT", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "SET_MINT" });
      else setNotifyStatus({ success: false, tag: "SET_MINT" });
    });

    ws.on("FREEZE_MINT", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "FREEZE_MINT" });
      else setNotifyStatus({ success: false, tag: "FREEZE_MINT" });
    });

    ws.on("REVOKE_FREEZE_MINT", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "REVOKE_FREEZE_MINT" });
      else setNotifyStatus({ success: false, tag: "REVOKE_FREEZE_MINT" });
    });

    ws.on("REMOVE_LP", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "REMOVE_LP" });
      else setNotifyStatus({ success: false, tag: "REMOVE_LP" });
    });

    ws.on("BURN_LP", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "BURN_LP" });
      else setNotifyStatus({ success: false, tag: "BURN_LP" });
    });

    ws.on("BURN_TOKEN", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "BURN_TOKEN" });
      else setNotifyStatus({ success: false, tag: "BURN_TOKEN" });
    });

    ws.on("CLOSE_TOKEN", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "CLOSE_TOKEN" });
      else setNotifyStatus({ success: false, tag: "CLOSE_TOKEN" });
    });

    ws.on("BOT_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK") 
        setNotifyStatus({ success: true, tag: "BOT_TOKEN" }); // insufficient
      else
        setNotifyStatus({ success: false, tag: "BOT_TOKEN" }); // Jito fail
    });
    
    ws.on("FREEZE_TOKEN", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK") 
        setNotifyStatus({ success: true, tag: "FREEZE_TOKEN" }); // insufficient
      else
        setNotifyStatus({ success: false, tag: "FREEZE_TOKEN" }); // Jito fail
    });

    setWebSocket(ws);
  };

  const closeWebSocket = () => {
    if (webSocket) webSocket.close();
    setWebSocket(null);
  };

  const updateTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating all balances...", token, wallets);
      if (!isValidAddress(token)) {
        setWalletTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setWalletTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      setWalletTokenBalanceData(wallets.map(() => "0"));
    }
  };

  const updateSolBalances = async (wallets) => {
    try {
      console.log("Updating all Bot SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setWalletSolBalanceData(balances);

      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      setWalletSolBalanceData(wallets.map(() => "0"));
    }
  };

  const loadAllProjects = async () => {
    let newProjects = [];
    setLoadingPrompt("Loading all projects...");
    setOpenLoading(true);
    try {
      console.log("Loading all projects...");
      const { data } = await axios.get(
        `${SERVER_URL}/api/v1/project/load-all`,
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );
      if (data.projects) {
        newProjects = data.projects;
        console.log ("App.js newProjects", newProjects);
      }
    } catch (err) {
      console.log(err);
      toast.warn("Failed to load projects");
    }
    setOpenLoading(false);
    setProjects(newProjects);
    setCurrentProject({});
  };

  const loadAllUsers = async () => {
    let newUsers = [];
    setLoadingPrompt("Loading all users...");
    setOpenLoading(true);
    try {
      console.log("Loading all users...");
      const { data } = await axios.get(`${SERVER_URL}/api/v1/user/load-all`, {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      });
      if (data.users) newUsers = data.users;
    } catch (err) {
      console.log(err);
      toast.warn("Failed to load users");
    }

    setOpenLoading(false);
    setUsers(newUsers);
  };

  const updateProject = (project) => {
    const newProjects = [...projects];
    for (let i = 0; i < newProjects.length; i++) {
      if (project._id === newProjects[i]._id) {
        newProjects[i] = project;
        break;
      }
    }
    setProjects(newProjects);
  };

  const initAllData = async (accessToken, user) => {
    let newProjects = [];

    setLoadingPrompt("Initializing...");
    setOpenLoading(true);

    try {
      console.log("Loading all projects...");
      const { data } = await axios.get(
        `${SERVER_URL}/api/v1/project/load-all`,
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": accessToken,
          },
        }
      );
      if (data.projects) newProjects = data.projects;

    } catch (err) {
      console.log(err);
      toast.warn("Failed to load projects");
    }

    setOpenLoading(false);
    setProjects(newProjects);
  };

  const logout = async () => {
    console.log("Logging out...");

    setLoadingPrompt("Logging out...");
    setOpenLoading(true);
    try {
      await axios.get(`${SERVER_URL}/api/v1/user/logout`, {
        headers: {
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      });
      localStorage.removeItem("access-token");

      setUsers([]);
      setProjects([]);
      setCurrentProject({});
      setUser(null);
      closeWebSocket();
    } catch (error) {
      console.log(error);
      toast.warn("Failed to logout");
    }
    setOpenLoading(false);
  };

  const holderTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating Holder TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setHolderTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setHolderTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setHolderTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const holderSolBalances = async (wallets) => {
    try {
      console.log("Updating HOLDER SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setHolderSOLBalanceData(balances);
      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setHolderSOLBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const sharkTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating Holder TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setSharkTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setSharkTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setSharkTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const sharkSolBalances = async (wallets) => {
    try {
      console.log("Updating HOLDER SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setWhaleSOLBalanceData(balances);
      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setWhaleSOLBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const whaleTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating Holder TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setWhaleTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setWhaleTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setWhaleTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const whaleSolBalances = async (wallets) => {
    try {
      console.log("Updating HOLDER SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setSharkSOLBalanceData(balances);
      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setSharkSOLBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const allWalletRefresh = async () => {
    const holer = currentProject.botWallets.filter(element => element.kind === "holder");
    const pageHolders = holer.splice((page+1) * PAGE_SIZE, PAGE_SIZE);
    const shark = currentProject.botWallets.filter(element => element.kind === "shark");
    const whale = currentProject.botWallets.filter(element => element.kind === "whale");
    console.log("page : ", page);
    holderSolBalances(pageHolders);
    holderTokenBalances(currentProject.name, pageHolders);
    sharkSolBalances(shark);
    sharkTokenBalances(currentProject.name, shark);
    whaleSolBalances(whale);
    whaleTokenBalances(currentProject.name, whale);
  }

  useEffect(() => {
    console.log("currentProject.token:", currentProject.token);
    if (currentProject.token) {
      setTokenAddress(currentProject.token.address);
    }
    if (currentProject.wallets && currentProject.wallets.length > 0) {
      updateTokenBalances(currentProject.token.address, currentProject.wallets);
      updateSolBalances(currentProject.wallets);
    } 
    else {
      setWalletSolBalanceData("0");
      setWalletTokenBalanceData("0");
    }
  }, [currentProject.token, currentProject.wallets]);


  useEffect(() => {
    if (currentProject.botWallets && currentProject.botWallets.length > 0) {
      let holder = currentProject.botWallets.filter(element => element.kind === "holder").slice(0, 3);
      setHolderWallets(holder);
      allWalletRefresh();
    } 
    else {
      setWalletSolBalanceData("0");
      setWalletTokenBalanceData("0");
    }
  }, [currentProject.botWallets]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (!user) {
      if (
        location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/register"
      ) {
        navigate("/login");
      }
    } else {
      if (
        location.pathname !== "/dashboard" &&
        location.pathname !== "/create-token" &&
        location.pathname !== "/set-authority" &&
        location.pathname !== "/openbook" &&
        location.pathname !== "/manage-lp" &&
        location.pathname !== "/token-account" &&
        location.pathname !== "/buy" &&
        location.pathname !== "/sell" &&
        location.pathname !== "/transfer" &&
        location.pathname !== "/bot" &&
        location.pathname !== "/freeze"
      ) {
        navigate("/dashboard");
      }
    }
  }, [location, navigate, user]);

  useEffect(() => {
    if (location.pathname === "/dashboard") setBreadCrumb("Dashboard");
    else if (location.pathname === "/buy") setBreadCrumb("Project > Buy");
    else if (location.pathname === "/sell") setBreadCrumb("Project > Sell");
    else if (location.pathname === "/transfer")
      setBreadCrumb("Project > Transfer");
    else if (location.pathname === "/create-token")
      setBreadCrumb("Tools > Create SPL Token");
    else if (location.pathname === "/set-authority")
      setBreadCrumb("Tools > Set Authority");
    else if (location.pathname === "/openbook")
      setBreadCrumb("Tools > Create OpenBook Market");
    else if (location.pathname === "/manage-lp")
      setBreadCrumb("Tools > Manage LP");
    else if (location.pathname === "/token-account")
      setBreadCrumb("Tools > Token Account");
    else if (location.pathname === "/bot") setBreadCrumb("Bot");
    else if (location.pathname === "/freeze") setBreadCrumb("Freeze");
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      console.log("Succeed to login");
      toast.success("Succeed to login");

      openWebSocket(user._id);

      const accessToken = localStorage.getItem("access-token");
      initAllData(accessToken, user);
    } else console.log("Logged out");
  }, [user]);

  useEffect(() => {
    if (notifyStatus.tag === "COLLECT_ALL_SOL") {
      if (notifyStatus.success) toast.success("Succeed to collect all SOL!");
      else toast.warn("Failed to collect all SOL!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "DISPERSE_COMPLETED") {
      if (notifyStatus.success) toast.success("Succeed to disperse!");
      else toast.warn("Failed to disperse!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "COLLECT_ALL_FEE") {
      if (notifyStatus.success) toast.success("Succeed to collect fee!");
      else toast.warn("Failed to collect fee!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "CREATE_TOKEN") {
      if (notifyStatus.success) toast.success("Succeed to create spl token!");
      else toast.warn("Failed to create spl token!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "CREATE_OPENBOOKMARKET") {
      if (notifyStatus.success)
        toast.success("Succeed to create OpenBookMarket!");
      else toast.warn("Failed to create OpenBookMarket!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "REVOKE_MINT") {
      if (notifyStatus.success) toast.success("Revoke mint success!");
      else toast.warn("Failed to revoke mint!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "SET_MINT") {
      if (notifyStatus.success) toast.success("Succeed to set mint authority!");
      else toast.warn("Failed to set mint authority!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "FREEZE_MINT") {
      if (notifyStatus.success) toast.success("Succeed to freeze authority!");
      else toast.warn("Failed to freeze authority!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "REVOKE_FREEZE_MINT") {
      if (notifyStatus.success) toast.success("Succeed to revoke freeze mint!");
      else toast.warn("Failed to revoke freeze mint!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "REMOVE_LP") {
      if (notifyStatus.success) toast.success("Succeed to remove LP!");
      else toast.warn("Failed to remove LP!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "BURN_LP") {
      if (notifyStatus.success) toast.success("Succeed to burn LP!");
      else toast.warn("Failed to burn LP!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "BURN_TOKEN") {
      if (notifyStatus.success) toast.success("Succeed to burn token!");
      else toast.warn("Failed to burn token!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "CLOSE_TOKEN") {
      if (notifyStatus.success) toast.success("Succeed to close spl token!");
      else toast.warn("Failed to close spl token!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "BOT_TOKEN") {
      if (notifyStatus.success) toast.warn("In sufficient SOL!"); // TODO: this is probably SOL is insufficient.
      else toast.warn("Jito failed");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "FREEZE_TOKEN") {
      if (notifyStatus.success) toast.success("Succeed to freeze token account!"); // TODO: this is probably SOL is insufficient.
      else toast.warn("Failed to freeze token account");
      setOpenLoading(false);
    }
  }, [notifyStatus]);

  useEffect(() => {
    if (connected) {
      console.log("Getting token accounts...");
      getTokenListByOwner(connection, publicKey, true).then((response) => {
        setAssets(response);
        console.log("Success");
      });
    } else setAssets([]);
  }, [connected, connection, publicKey]);

  return (
    <AppContext.Provider
      value={{
        SERVER_URL,
        setLoadingPrompt,
        setOpenLoading,
        logout,
        user,
        setUser,
        users,
        setUsers,
        projects,
        setProjects,
        currentProject,
        setCurrentProject,
        assets,
        setAssets,
        webSocket,
        setWebSocket,
        openWebSocket,
        closeWebSocket,
        loadAllProjects,
        loadAllUsers,
        updateProject,
        walletTokenBalanceData,
        setWalletTokenBalanceData,
        walletSolBalanceData,
        setWalletSolBalanceData,
        updateTokenBalances,
        updateSolBalances,
        notifyStatus,
        setNotifyStatus,
        setNotifyTitle,
        setNotifyAddress,
        setNotifyAddressDialog,
        setTokenAddress,
        tokenAddress,
        setZombieAddress,
        zombieAddress,
        setBotZombieAddress,
        botZombieAddress,
        startHolderBot,
        startVolumeBot,
        setStartHolderBot,
        setStartVolumeBot,
        holderMaxbuy,
        sharkMaxbuy,
        whaleMaxBuy,
        setHolderMaxBuy,
        setSharkMaxBuy,
        setWhaleMaxBuy,
        setHolderWallets,
        holderWallets,
        holderSOLBalanceData,
        setHolderSOLBalanceData,
        holderTokenBalanceData,
        setHolderTokenBalanceData,
        sharkSOLBalanceData,
        setSharkSOLBalanceData,
        sharkTokenBalanceData,
        setSharkTokenBalanceData,
        whaleSOLBalanceData,
        setWhaleSOLBalanceData,
        whaleTokenBalanceData,
        setWhaleTokenBalanceData,
        holderSolBalances,
        holderTokenBalances,
        sharkSolBalances,
        sharkTokenBalances,
        whaleSolBalances,
        whaleTokenBalances,
        allWalletRefresh,
        setPage,
        page
      }}
    >
      <LoadingDialog isOpen={openLoading} prompt={loadingPrompt} />
      <NotifyAddressDialog
        isOpen={notifyAddressDialog}
        title={notifyTitle}
        address={notifyAddress}
        onClose={() => setNotifyAddressDialog(false)}
      />
      {user ? (
        <div className="flex flex-col min-h-[100vh] overflow-x-hidden backdrop-blur-md">
          <div className="relative flex items-start justify-between w-full h-max">
            <SideBar className="2xl:block  w-[70px] 2xl:w-[300px] h-[100vh] shadow-md" />
            <div className="flex flex-col w-full 2xl:w-[calc(100%-300px)] h-screen">
              <div className="px-1 relative">
                <NavBar className="flex w-full h-[100px]" />
                <div className="flex items-center justify-between w-full h-10 px-5 py-3 bg-baseColor bg-opacity-50">
                  <div className="text-base font-medium leading-normal text-white font-poppins">
                    {breadCrumb}
                  </div>
                </div>
                <div className="w-full h-[calc(100vh-200px)] overflow-y-auto">
                  <Routes>
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/create-token" element={<CreateTokenPage />} />
                    <Route
                      path="/set-authority"
                      element={<SetAuthorityPage />}
                    />
                    <Route path="/openbook" element={<OpenBookMarketPage />} />
                    <Route path="/manage-lp" element={<ManageLpPage />} />
                    <Route
                      path="/token-account"
                      element={<TokenAccountPage />}
                    />
                    <Route path="/buy" element={<BuyPage />} />
                    <Route path="/sell" element={<SellPage />} />
                    <Route path="/transfer" element={<TransferPage />} />
                    <Route path="/bot" element={<BotPage />} />
                    <Route path="/freeze" element={<FreezePage />} />
                  </Routes>
                </div>
              </div>
              <div className="flex flex-1 bg-gradient-to-r from-[#ffffff0d] to-[#ffffff05] text-white justify-center items-center ">
                <label>Copyright © 2024. All rights reserved.</label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/register" element={<SignupPage />} />
          <Route path="/login" element={<SigninPage />} />
          <Route path="/" element={<SigninPage />} />
        </Routes>
      )}
    </AppContext.Provider>
  );
}

export default App;
